import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor() { }
  
  // fab(e) {
  //   let fab1 = document.getElementById('fab1');
  //   let innerFabs = document.getElementsByClassName('inner-fabs')[0];
  //   innerFabs.classList.toggle('show');
  //   switch (e.target.id) {
  //     case "fab1":
  //     case "fab2":
  //     case "fab3":
  //     case "fabIcon":
  //       break;
  //     default:
  //       innerFabs.classList.remove('show');
  //       break;
  //   }

  // }

  ngOnInit() {
  }

}
