import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {

  constructor() { }

  // privacy(d, s, id) {
  //   var js, tjs = d.getElementsByTagName(s)[0];
  //   if (d.getElementById(id)) 
  //       return;
  //   js = d.createElement(s); 
  //   js.id = id;
  //   js.src = "https://app.termly.io/embed-policy.min.js";
  //   tjs.parentNode.insertBefore(js, tjs);
  // }

  ngOnInit() {
    // var d,s,id;
    // this.privacy(d, s, id);
  }

}
