import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dcma',
  templateUrl: './dcma.component.html',
  styleUrls: ['./dcma.component.scss']
})
export class DcmaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
